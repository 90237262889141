
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../lib/GridBase';
@import '../lib/GridWidths';

$columns: (
  m: (
    1,
    2,
    3,
    5
  ),
  t: (
    1,
    3,
    4,
    8,
    12
  ),
  tl: (
    2,
    3,
    5
  ),
  d: (
    3,
    4,
    5
  ),
  dm: (
    3,
    4,
    5
  ),
  dl: (
    3,
    4,
    5
  )
);

.GridItem {
  @include grid-base;
}

@include grid-widths('m', $columns);

@include mq($breakpoint-tablet) {
  @include grid-widths('t', $columns);
}

@include mq($breakpoint-tabletLandscape) {
  @include grid-widths('tl', $columns);
}

@include mq($breakpoint-desktop) {
  @include grid-widths('d', $columns);
}

@include mq($breakpoint-desktopMedium) {
  @include grid-widths('dm', $columns);
}

@include mq($breakpoint-desktopLarge) {
  @include grid-widths('dl', $columns);
}

.trailingGap-default {
  margin-bottom: spacing(2);
}

.trailingGap-mobile {
  @include mq($max: $breakpoint-tablet) {
    margin-bottom: spacing(2);
  }
}

.grow {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.mobile-carousel {
  @include mq(0, $breakpoint-tabletLandscape - 1) {
    width: 45%;
    white-space: normal;
    padding-left: spacing(2);

    &:last-child {
      margin-right: spacing(3);
    }

    &:first-child {
      margin-left: spacing(1);
      padding-left: spacing(2);
    }
  }

  @include mq(0, $breakpoint-tablet - 1) {
    white-space: normal;
    width: 70%;

    &:last-child {
      margin-right: spacing(2);
    }
  }
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   columns: map-keys($columns);
// }
