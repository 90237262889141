
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.rounded {
  border-radius: $radius-standard;
}

.shadow {
  box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
}

.solid {
  background-color: $color-primary-white;

  &.inverse {
    background-color: $color-primary-grey;
  }
}

.wide {
  @include mq($breakpoint-desktopMedium) {
    margin: 0 spacing(-4);
  }
}

.padded {
  padding: spacing(3) 0;

  @include mq($breakpoint-desktopMedium) {
    padding: spacing(4) 0;
  }
}
