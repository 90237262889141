
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ShrinkWrapWrapper {
  display: table;
  width: auto;
}

.fixed {
  table-layout: fixed;
}

.fullWidth {
  width: 100%;
}
