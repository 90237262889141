.SubNavigation {
  padding: spacing(2) spacing(3);

  @include mq($breakpoint-desktop) {
    padding: spacing(3) spacing(4);
  }
}

.List {
  list-style: none;
}

.Title {
  color: $color-primary-teal;
  font-weight: bold;
  padding-bottom: spacing(2);
}

.ListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: $duration-short $easing-standard;

  a {
    display: block;
    width: 100%;
    padding: spacing(2);

    @include mq($breakpoint-desktopMedium) {
      padding: spacing(3);
    }
  }
}

.Name {
  text-align: left;
}

.ChevronRight {
  margin-left: spacing(2);
}

.Rating {
  margin-right: spacing(1);
  width: 33px !important;
  height: 29.3333px !important;

  @include mq($breakpoint-tabletLandscape) {
    width: 33px !important;
    height: 29.3333px !important;
  }

  @include mq($breakpoint-desktop) {
    width: 55px !important;
    height: 48.8889px !important;
  }

  @include mq($breakpoint-desktopMedium) {
    width: 63px !important;
    height: 56px !important;
  }
}
