
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../_SubNavigationCommon';

.SubNavigationWrapper {
  position: relative;
}

.SubNavigation {
  padding: spacing(3) spacing(4) spacing(4);
  border-radius: $radius-standard;

  &.isDark {
    background-color: $color-primary-grey;
  }

  &.isTeal {
    background-color: $color-primary-teal;
  }

  &.isOrange {
    background-color: $color-highlight-orange;
  }
}

.Pointer {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, 0);

  path {
    fill: $color-highlight-orange;
  }

  &.isDark {
    path {
      fill: $color-primary-grey;
      box-shadow: 0 0 48px 16px rgba(0, 0, 0, 0.16);
    }
  }
}

.Line {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;

  @include mq(0, $breakpoint-tablet - 1) {
    display: none;
  }
}

.Triangles {
  position: absolute;
  top: -70px;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, 0);
}

.Title {
  padding-top: 0;

  .isDark &,
  .isTeal &,
  .isOrange & {
    color: $color-primary-white;
  }
}

.ListItem {
  border-bottom: 1px solid $color-primary-grey-lighter;
  &:last-child {
    border-bottom: 0;
  }
  &:nth-child(even) {
    background-color: $color-primary-grey-white;
  }

  &:nth-child(odd) {
    background-color: $color-primary-grey-lightest;
  }

  .isDark & {
    border-bottom: 3px solid $color-primary-grey;
    .ChevronRight svg path {
      fill: $color-primary-white;
    }
    &:nth-child(even) {
      background-color: $color-primary-grey-mid;
    }

    &:nth-child(odd) {
      background-color: $color-primary-grey-mid;
    }

    color: $color-primary-white;
  }

  .isTeal & {
    border-bottom: 3px solid $color-primary-teal;
    .ChevronRight svg path {
      fill: $color-primary-white;
    }
    &:nth-child(even) {
      background-color: rgba($color-primary-white, 0.2);
    }

    &:nth-child(odd) {
      background-color: rgba($color-primary-white, 0.2);
    }

    color: $color-primary-white;
  }

  .isOrange & {
    border-bottom: 3px solid $color-highlight-orange;
    .ChevronRight svg path {
      fill: $color-primary-black;
    }
    &:nth-child(even) {
      background-color: rgba($color-primary-white, 0.2);
    }

    &:nth-child(odd) {
      background-color: rgba($color-primary-white, 0.2);
    }

    color: $color-primary-black;
  }

  &:hover {
    transition: $duration-short $easing-standard;
    color: $color-primary-teal;

    .ChevronRight svg path {
      transition: $duration-short $easing-standard;
      fill: $color-primary-teal;
    }

    .isTeal &,
    .isOrange & {
      color: $color-primary-white;

      .ChevronRight svg path {
        fill: $color-primary-white;
      }
    }

    .isTeal & {
      background-color: $color-primary-teal;
    }

    .isOrange & {
      background-color: $color-highlight-orange;
    }
  }
}
