
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ShrinkWrapItem {
  display: table-cell;

  &:not(:first-child) {
    padding-left: spacing(0.5);

    &.comfortable {
      padding-left: spacing(1.5);
    }
  }

  &:not(:last-child) {
    padding-right: spacing(0.5);

    &.comfortable {
      padding-right: spacing(1.5);
    }
  }
}

.shrink {
  // This will expand to fit the minimum width of its content
  width: 1px;
}

.noWrap {
  white-space: nowrap;
}

// vAlign

.top {
  vertical-align: top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}
