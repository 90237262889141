
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../lib/GridGutter';
@import '../lib/GridReverse';

$gutters: (
  'tiny': spacing(2),
  'small': spacing(3),
  'default': spacing(4),
  'large': spacing(4)
);

$desktop-gutters: (
  'default': spacing(4),
  'large': spacing(14)
);

// .GridWrapper {}
@include grid-gutters($gutters);

@include grid-reverse();

@include mq($breakpoint-tablet) {
  @include grid-reverse(t);
}

@include mq($breakpoint-desktop) {
  @include grid-reverse(d);
  // @include grid-gutters($desktop-gutters);
}

@include mq($breakpoint-desktopMedium) {
  // @include grid-reverse(d);
  @include grid-gutters($desktop-gutters);
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.mobile-reversed-flex {
  @include mq($max: $breakpoint-tabletLandscape - 1) {
    flex-direction: column-reverse;
  }
}

.valign-middle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mobile-carousel {
  display: flex;
  flex-wrap: wrap;

  @include mq(0, $breakpoint-tabletLandscape - 1) {
    display: block;
    white-space: nowrap;
    overflow: auto;
  }
}

// :export {
//   /* stylelint-disable-next-line property-no-unknown */
//   gutters: map-keys($gutters);
// }
