
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../_SubNavigationCommon';

.Title {
  color: $color-primary-teal;

  .isDark & {
    color: $color-primary-white;
  }
}

.ListItem {
  border-bottom: 1px solid $color-primary-grey-lighter;

  &:last-child {
    border-bottom: 0;
  }

  &:nth-child(even) {
    background-color: $color-primary-grey-white;
  }

  &:nth-child(odd) {
    background-color: $color-primary-grey-lightest;
  }

  &:hover {
    transition: $duration-short $easing-standard;
    color: $color-primary-teal;

    .ChevronRight svg path {
      transition: $duration-short $easing-standard;
      fill: $color-primary-grey;
    }
  }
}

.isDark {
  .ListItem {
    color: $color-primary-white;
    border-color: $color-primary-grey;

    &:nth-child(even) {
      background-color: $color-primary-grey-mid-light;
    }

    &:nth-child(odd) {
      background-color: $color-primary-grey-mid;
    }

    .ChevronRight svg path {
      fill: $color-primary-white;
    }

    &:hover {
      color: $color-primary-teal;
      .ChevronRight svg path {
        fill: $color-primary-teal;
      }
    }
  }
}
