
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.ResponsiveSpacer {
  height: spacing(4);

  @include mq($breakpoint-tablet) {
    height: spacing(6);
  }

  @include mq($breakpoint-desktop) {
    height: spacing(7);
  }

  @include mq($breakpoint-desktopMedium) {
    height: spacing(8);
  }

  @include mq($breakpoint-desktopLarge) {
    height: spacing(9);
  }

  // &:last-child {
  //   display: none;

  //   &:nth-last-child(n + 1):nth-last-child(-n + 1):first-child,
  //   &:nth-last-child(n + 1):nth-last-child(-n + 1):first-child ~ & {
  //     display: block;
  //   }
  // }
}

.small {
  height: spacing(2);

  @include mq($breakpoint-desktopMedium) {
    height: spacing(3);
  }

  @include mq($breakpoint-desktopLarge) {
    height: spacing(4);
  }
}
